
import {defineComponent, ref} from "vue";
import MetricValueUpdateForm from "@/views/metric/MetricValueUpdateForm.vue";

export default defineComponent({
  name: "UpdateValue",
  components: {MetricValueUpdateForm, },
  props: {
    id: {type: String},
    token: {type: String},
    title: {type: String},
    type: {default: () => 'NUMBER'}
  },
  setup() {
    const submitting = ref(false);
    return {
      submitting,
    }
  },
  methods: {
    onSave() {
      this.submitting = true;
    }
  }
})
